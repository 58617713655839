// Fonts
// @import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
// @import url(https://fonts.googleapis.com/css?family=Advent+Pro|Open+Sans|Dosis:300,400);
// @import url(https://fonts.googleapis.com/css?family=Allerta+Stencil|Playfair+Display+SC:700|Rajdhani:700|Share:400,700|Teko:500);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,300,400,600,700,900);
// @import url('https://fonts.googleapis.com/css?family=Abel|Roboto+Condensed|Ubuntu+Condensed');
// Variables
@import "variables";
// Bootstrap
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
$main-blue: #1C81EB;
$main-red: #FC3327;
$main-yellow: #FBD86B;
$main-black: #292b2c;
$main-blue2: #5cb3fd;
$error-red: #bb3d3d;

@mixin vc {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin transition-1 ($time: 0.3s) {
    transition: all $time cubic-bezier(.18, .78, .85, .95);
    -webkit-transition: all $time cubic-bezier(.18, .78, .85, .95);
    -moz-transition: all $time cubic-bezier(.18, .78, .85, .95);
    -spec-transition: all $time cubic-bezier(.18, .78, .85, .95);
}

@mixin transition-2 ($time: 0.3s) {
    -webkit-transition: all $time ease-in;
    -moz-transition: all $time ease-in;
    -spec-transition: all $time ease-in;
    transition: all $time ease-in;
}

.no-transition {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

::selection {
    color: black;
    background: #ccc;
}

.btn,
button,
a {
    &:focus {
        outline: 0 !important;
    }
}

.general-btn {
    min-width: 150px;
    color: white;
    background: #131313;
    border: 1px solid #131313;
    letter-spacing: .1em;
    text-transform: uppercase;
    font-size: .75em;
    cursor: pointer;
    @include transition-2(0.1s);
    font-family: 'Source Sans Pro', sans-serif;
    border-radius: 0;

    &:hover {
        background: #ccc;
        border-color: #ccc;
        color: black;
        text-decoration: none;
    }
}

body {
    // font-family: 'Open Sans', sans-serif;
    // font-family: 'Dosis', sans-serif;
    // font-family: 'Roboto Condensed', sans-serif;
    // font-family: 'Ubuntu Condensed', sans-serif;
    // font-family: 'Abel', sans-serif;
    font-family: 'Source Sans Pro', sans-serif;
    cursor: auto;
    overflow-x: hidden;
}

// *:not(.information-slick, .information-slick *, .product-slider-box, .product-slider-box *) {
//     @include transition-1();
// }
img {
    max-width: 100%;
}

img:not([src]) {
    visibility: hidden;
}


/* Fixes Firefox anomaly during image load */

@-moz-document url-prefix() {
    img:-moz-loading {
        visibility: hidden;
    }
}

#site-loading {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 9999;
    display: table;
    background: white;
    line-height: 100%;

    .loading-container {
        display: table-cell;
        vertical-align: middle;
    }

    .spinner {
        margin: 100px auto;
        width: 50px;
        height: 40px;
        text-align: center;
        font-size: 10px;

        &>div {
            background-color: #333;
            height: 100%;
            width: 6px;
            display: inline-block;
            -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
            animation: sk-stretchdelay 1.2s infinite ease-in-out;
        }

        .rect2 {
            -webkit-animation-delay: -1.1s;
            animation-delay: -1.1s;
        }

        .rect3 {
            -webkit-animation-delay: -1.0s;
            animation-delay: -1.0s;
        }

        .rect4 {
            -webkit-animation-delay: -0.9s;
            animation-delay: -0.9s;
        }

        .rect5 {
            -webkit-animation-delay: -0.8s;
            animation-delay: -0.8s;
        }

        @-webkit-keyframes sk-stretchdelay {

            0%,
            40%,
            100% {
                -webkit-transform: scaleY(0.4)
            }

            20% {
                -webkit-transform: scaleY(1.0)
            }
        }

        @keyframes sk-stretchdelay {

            0%,
            40%,
            100% {
                transform: scaleY(0.4);
                -webkit-transform: scaleY(0.4);
            }

            20% {
                transform: scaleY(1.0);
                -webkit-transform: scaleY(1.0);
            }
        }
    }
}

h1,
.h-title {
    font-family: 'Source Sans Pro', sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
}

header {
    position: relative; // position: sticky;
    top: 0;
    width: 100%;
    background: white;
    z-index: 999;

    &.fixed-header {
        position: fixed;
        top: 0;
        background: rgba(255, 255, 255, 0.95); // box-shadow: 0px 1px 5px #aaa;
    }

    &.sticky-header {
        background: rgba(255, 255, 255, 0.6);
        display: inline-block;
        position: sticky;
        box-shadow: 0px 1px 5px #aaa;
    }

    .menu-container {
        padding: 0;
        margin: 1em 2em;
        position: relative;

        * {
            -webkit-transition: width 0.3s ease-in;
            -moz-transition: width 0.3s ease-in;
            -spec-transition: width 0.3s ease-in;
            transition: width 0.3s ease-in;
        }

        a {
            color: black;
            display: inline-block;
        }

        .menu-left,
        .menu-right {
            height: 100%;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            -ms-flex-align: center;
            -webkit-align-items: center;
            -webkit-box-align: center;
            align-items: center; // a {

            //     letter-spacing: 1px;
            // }
            img {
                width: 25px;
            }
        }

        .menu-left {
            position: absolute;
            left: 0;
            top: 0;

            &>a {
                margin-right: 1em;
                text-align: center;

                @media (min-width: 650px) {
                    width: 3rem;
                }
            }
        }

        .menu-right {
            position: absolute;
            right: 0;
            top: 0; // font-family: 'Dosis', sans-serif;

            &>a,
            &>div {
                margin-left: 1em;
                text-align: center;

                @media (min-width: 650px) {
                    width: 3rem;
                }
            }

            &a:first {
                margin-left: 0em;
            }

            a#ddlCurrency {
                letter-spacing: 2px;
                cursor: pointer;

                &+.dropdown-menu {
                    // margin-left: -11px;
                    // left: -37%;
                    left: -18px;
                }
            }

            a#ddlLanguage {
                letter-spacing: 3px;
                cursor: pointer;

                &+.dropdown-menu {
                    // margin-left: -18px;
                    // left: -38%;
                    left: -17px;

                    a {
                        text-align: center;
                    }
                }
            }

            .dropdown-menu {
                min-width: 3rem;
                border-radius: 0;

                a:active {
                    background-color: #333;
                }
            }

            .cart-container {
                position: relative;

                span {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    height: 15px;
                    width: 15px;
                    line-height: 14px;
                    font-size: 12px;
                    color: white;
                    background: #020202;
                    border-radius: 100%;
                    text-align: center;
                    padding: 1px;
                }
            }

            .cart-container2 {
                position: relative;

                .dropdown-toggle {
                    &:after {
                        content: none;
                    }

                    span {
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        height: 15px;
                        width: 15px;
                        line-height: 14px;
                        font-size: 12px;
                        color: white;
                        background: #020202;
                        border-radius: 100%;
                        text-align: center;
                        padding: 1px;
                    }
                }

                .dropdown-menu {
                    left: -15rem;
                    min-width: 17.5rem;
                    padding: 0;
                    z-index: 999;

                    p.empty {
                        padding: 10px;
                        margin: 0;
                        text-align: center;
                        font-size: 0.8em;
                    }

                    .product-list {
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li.item {
                            padding: 10px 5px;
                            border-bottom: 1px solid #eee;

                            img {
                                float: left;
                                width: 75px;
                            }

                            .content {
                                padding-left: 80px;

                                p {
                                    margin-bottom: 3px;
                                    text-transform: uppercase;
                                    font-size: 0.8em;
                                    line-height: 1.2em;
                                }

                                span {
                                    font-size: .65em;
                                    color: $main-black;
                                    display: block;
                                    line-height: 1.3em;
                                    text-transform: uppercase;

                                    &.price {
                                        div {
                                            display: inline-block;

                                            s {
                                                color: #999;
                                            }
                                        }

                                        .ml-l {
                                            margin-left: -2px;
                                            margin-right: 7px;
                                        }
                                    }
                                }
                            }
                        }

                        li.go-to-cart {
                            // background-color: $main-black;
                            text-align: center;
                            text-transform: uppercase;
                            font-size: .75em;

                            a {
                                display: block;
                                padding: 10px; // color: white;
                                letter-spacing: 1px;

                                &:hover,
                                &:active {
                                    color: $main-black; // text-decoration: none; 
                                    background-color: white;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media(max-width: 768px) {
            .menu-center {
                img {
                    max-width: 75px !important;
                }
            }
        }

        @media(max-width: 500px) {
            margin: 0.5em 1em;

            .menu-center {
                img {
                    max-width: 60px !important;
                }
            }
        }
    }
}

#menu-padding {
    height: 149px;

    @media(max-width: 500px) {
        height: 109px;
    }

    display: none;
}

.information-container {
    // font-family: 'Dosis', sans-serif;
    height: 34px;
    padding: 5px 0;
    overflow: hidden;
    letter-spacing: 0.1em;

    .information-slider {

        .slide-left,
        .slide-right {
            display: inline-block;
            position: absolute;
            line-height: 24px;
            top: 0;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-size: 15px 15px;
            background-position: center center;
            cursor: pointer;
        }

        .slide-left {
            background-image: url('/images/arrow-left.png');
            left: 0;
        }

        .slide-right {
            background-image: url('/images/arrow-right.png');
            right: 0;
        }

        .slick-slide {
            line-height: 24px;
            font-size: 0.85em;
            outline: none;
        }
    }
}

#index-wrapper {

    .product-slider-container,
    .instagram-container,
    .blog-container {
        margin-top: 5em;
        margin-bottom: 5em;
    }

    .banner-container {
        padding-top: 0 !important;

        .item {
            outline: none !important;
            box-shadow: none !important;
        }

        img {
            width: 100%;
        }
    }

    .sub-banner-container {
        h4 {
            font-weight: bold;
        }

        a {
            color: black;

            img {
                width: 100%;
            }

            &:hover {
                text-decoration: none;

                .image-box {
                    img {
                        opacity: 0.8;
                    }
                }
            }

            .content-box {
                p {
                    line-height: 1.2em;
                    font-size: 0.9em;
                }
            }
        }
    }

    .product-slider-container {
        .type {
            a {
                color: black;
                font-size: 2em;
                padding: 0;

                @media(max-width: 576px) {
                    font-size: 20px;
                    margin: 0 10px !important;
                }

                &::before {
                    background-color: #444 !important;
                    height: 2px !important;
                }

                &:hover {
                    text-decoration: none;
                }

                &:not(:last-child) {
                    margin-right: 2em;

                    @media(max-width: 768px) {
                        margin-right: 1em;
                    }
                }

                &:focus {
                    text-decoration: none !important;
                }

                &.active {
                    &::before {
                        left: 0;
                        right: 0;
                    }
                }
            }
        }

        .slick-next::before,
        .slick-prev::before {
            color: black;
        }

        .slick-slide {
            outline: 0 !important;
        }

        .slide-left,
        .slide-right {
            display: inline-block;
            position: absolute;
            line-height: 24px;
            top: 50%;
            margin-top: -12px;
            width: 24px;
            height: 24px;
            background-repeat: no-repeat;
            background-size: 15px 15px;
            background-position: center center;
            cursor: pointer;
        }

        .slide-left {
            background-image: url('/images/arrow-left.png');
            left: 0;
        }

        .slide-right {
            background-image: url('/images/arrow-right.png');
            right: 0;
        }

        .slick-slide {
            line-height: 24px;
            font-size: 0.85em;
            outline: none;
        }

        .product-slider-box {
            .product {
                .image-box {}

                .content-box {}
            }
        }

        .promotion-loading {
            display: none;
            width: 100%;
            position: absolute;
            z-index: 999;

            .holder {
                position: relative;
                text-align: center;
            }
        }
    }

    .subscribe-container {
        // background-image: url('/images/subscribe2.jpg');
        // background-repeat: no-repeat;
        // background-attachment: fixed;
        // background-position: center top;
        // background-size: 100% auto;
        background: url('/images/subscribe2.jpg') no-repeat center center fixed;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        height: 300px;
        position: relative;
        display: inline-table;

        @media (max-width: 767px) {
            background-attachment: initial;
        }

        .overlay {
            position: absolute;
            background-color: rgba(0, 0, 0, 0.6);
        }

        .content {
            display: table-cell;

            h1 {
                color: white;
            }

            input,
            button {
                border: none;
                background: transparent;
                color: white;
                border-radius: 0;
            }

            input {
                border-bottom: 1px solid white;
                font-weight: 300;
                font-family: 'Source Sans Pro', sans-serif;

                &::-webkit-input-placeholder {
                    color: white;
                }

                &:-moz-placeholder {
                    /* FF 4-18 */
                    color: white;
                }

                &::-moz-placeholder {
                    /* FF 19+ */
                    color: white;
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: white;
                }
            }
        }
    }

    .blog-container {
        .blogs {
            .blog {
                @media (max-width: 576px) {
                    &:not(:last-child) {
                        margin-bottom: 3em !important;
                    }
                }

                .image-box {
                    margin-bottom: 1em;

                    img {
                        width: 100%;
                    }
                }

                .content-box {
                    h6 {
                        // font-family: 'Dosis', sans-serif;
                        text-transform: uppercase;
                        letter-spacing: 2px;
                        margin: 0.75em 0; // font-weight: bold;
                    }

                    p {
                        font-size: 0.85em;
                        margin: 0;
                    }

                    p.date {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}

.hand-divider {
    position: relative; // border-bottom: 1px solid #f0f0f0;
    border-bottom: 1px solid #333; // margin-bottom: 50px;
    // margin-top: 50px;
    margin-top: 3em;
    margin-bottom: 5em;

    &:before {
        position: absolute;
        content: '';
        width: 50px;
        height: 50px; // border: 1px solid #f0f0f0;
        border: 1px solid #333;
        left: 50%;
        margin-left: -25px;
        top: 50%;
        background: #fff;
        margin-top: -25px;
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    &:after {
        position: absolute;
        content: '';
        width: 30px;
        height: 30px;
        left: 50%;
        margin-left: -15px;
        top: 50%;
        background-color: white;
        background-image: url('/images/hand.png');
        background-size: 30px 30px;
        background-repeat: no-repeat;
        margin-top: -15px;
    }
}

footer {
    height: 400px;
    background-color: #333;
    display: inline-table;
    text-align: center;
    width: 100%;

    .content {
        display: table-cell;

        img {
            display: inline-block;
        }

        .company-information {
            p {
                font-size: 0.9em;
                color: white;

                &:not(:last-child) {
                    margin-bottom: 0;
                }

                a {
                    color: white;
                }
            }
        }

        ul.social {
            list-style: none;
            padding: 0;

            li {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: 2em;
                }

                a {
                    color: white;
                    position: relative;

                    &:hover {
                        opacity: 0.8;
                    }

                    &:before {
                        position: absolute;
                        content: '';
                        width: 26px;
                        height: 26px;
                        border: 1px solid white;
                        left: 50%;
                        margin-left: -13px;
                        top: 50%;
                        margin-top: -13px; // -webkit-transform: rotate(45deg);
                        // -moz-transform: rotate(45deg);
                        // -ms-transform: rotate(45deg);
                        // transform: rotate(45deg);
                        border-radius: 100%;
                    }
                }
            }
        }
    }

    .terms {
        display: table-row-group;

        a {
            display: inline-block;
            color: white;
            text-transform: uppercase;
            font-size: 0.8em;
            padding: 10px;
        }
    }

    .payment-row {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 20px;
        filter: grayscale(1);

        img {
            width: 20%;
            max-width: 75px;
        }
    }
}

#instafeed {
    @media(max-width: 992px) {
        figure:nth-child(n+7) {
            display: none;
        }

        figure {
            figcaption {
                padding: 10px !important;
            }
        }
    }

    @media(max-width: 576px) {
        figure:nth-child(n+5) {
            display: none;
        }
    }

    figure {
        position: relative; // width: 25%;
        display: inline-flex;
        margin: 0;
        float: left; // padding: 0;
        margin-bottom: 15px;

        a {
            position: relative;
            display: inline-block;
            color: white;
            width: 100%;
            background-repeat: no-repeat;
            width: 100%;
            background-size: 100% 100%;

            img {
                width: 100%;
                height: 100%;
            }
        }

        @media(max-width: 576px) {
            &.offset-2 {
                margin-left: 16.666667% !important;
            }
        }

        &:hover {
            figcaption {
                display: block !important;
            }
        }

        figcaption {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.8); // padding: 10px;
            padding: 3em;
            overflow: hidden;
            font-size: 0.875em;

            p {
                color: white;
            }

            ul {
                position: absolute;
                bottom: 10px;
                right: 10px;
                margin: 0;
                padding: 0;
                line-height: 1;
                pointer-events: auto;

                li {
                    display: inline-block;

                    &:last-child {
                        margin-left: 15px;
                    }
                }
            }
        }
    }
}

.products {
    .product {
        color: $main-black;
        cursor: pointer;
        padding: 10px;
        position: relative;
        display: inline-block;
        display: block;

        .new,
        .sales,
        .restock {
            position: absolute;
            top: 0;
            text-transform: lowercase;
            font-size: 0.85em;
            padding: 3px 7px;
            color: white; // font-family: 'Dosis', sans-serif;
            z-index: 99;
        }

        .new {
            left: 0;
            background-color: $main-blue;
        }

        .restock {
            left: 0;
            background-color: $main-yellow;
        }

        .sales {
            right: 0;
            background-color: $main-red;
        }

        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }

        .image-box {
            position: relative;
            padding-bottom: 0.5em;

            .out-of-stock {
                background: #ccc;
                color: $main-black;
                padding: 5px 10px;
                text-transform: uppercase;
                position: absolute;
                top: 50%;
                width: 120px;
                left: 50%;
                margin-top: -17px;
                margin-left: -60px;
                text-align: center;
            }
        }

        // &:hover {
        //     .image-box {
        //         img:first-child {
        //             display: none;
        //         }
        //         img:nth-child(2) {
        //             display: block;
        //         }
        //     }
        // }
        h6 {
            // font-family: 'Dosis', sans-serif;
            text-transform: uppercase; // letter-spacing: 2px;
            letter-spacing: 0.05em; // text-align: center;
            // font-weight: bold;
            border-bottom: 1px solid #666;
            padding-bottom: 5px;
            margin-bottom: 5px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        p {
            font-size: 0.85em;
            margin: 0; // text-align: center;
        }

        p.name {
            height: 2em;
            line-height: 1em;
            margin-bottom: 5px;
            text-transform: uppercase;
        }

        p.price {
            s {
                color: #999;
            }
        }

        p.colors {
            color: #999;

            span {
                width: 16px;
                height: 16px;
                display: inline-block;
                margin-right: 4px; // border-radius: 100%;
                border: 1px solid;

                &.black {
                    background: black;
                    border-color: black;
                }

                &.blue {
                    background: blue;
                    border-color: blue;
                }

                &.white {
                    background: white;
                    border-color: #ccc;
                }
            }
        }
    }
}

#products-wrapper {
    position: relative;

    h4,
    h6 {
        // font-family: 'Dosis', sans-serif;
    }

    main {
        margin: 3em 0 100px;

        * {
            transition: none !important;
        }
    }

    .product {
        margin-bottom: 2em;
    }

    .products-sidebar-container {
        h4 {
            text-transform: uppercase;
        }

        .slidedown-control-container {
            display: none;
            margin-bottom: 2em;

            .control {
                display: block;
                color: $main-black;
                border-top: 1px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;
                text-decoration: none;

                img {
                    width: 25px;
                }

                &.filter-button {
                    border-right: 1px solid #e5e5e5;
                    padding: 1em;

                    img {
                        margin-right: 10px;
                    }
                }

                &.sort-button {
                    border-left: 1px solid #e5e5e5;
                    margin-left: -1px;

                    img {
                        margin-left: 10px;
                    }

                    &>a {
                        color: $main-black;
                        display: block;
                        padding: 1em;

                        &:focus,
                        &:active,
                        &:hover {
                            text-decoration: none;
                        }
                    }

                    .dropdown-menu {
                        width: calc(100% + 1px);
                        margin-top: 0;
                        border-radius: 0;
                        border-color: #e5e5e5;
                        border-right: 0;
                        left: -1px;
                    }
                }
            }
        }

        @media(max-width: 768px) {
            .slidedown-control-container {
                display: block;
            }

            .slidedown-container {
                display: none;
            }
        }

        .categories-container,
        .brands-container {
            margin-bottom: 2em;

            ul {
                padding: 1em;
                border-top: 1px solid #e5e5e5;

                li {
                    list-style: none;
                    margin-bottom: 5px;

                    a {
                        color: #292b2c; // font-size: 0.95em;
                        text-transform: uppercase;
                        font-size: 0.9em; // font-family: 'Dosis', sans-serif;
                    }
                }
            }
        }

        .filters-container {
            margin-bottom: 2em; // position: -webkit-sticky;  // required for Safari

            // position: sticky;
            // top: 0; // required as well.
            .filters {
                .filter {
                    padding: 1em;
                    border-top: 1px solid #e5e5e5;
                    border-bottom: 1px solid #e5e5e5;
                    margin-bottom: -1px;

                    * {
                        @extend .no-transition;
                    }

                    h6 {
                        text-transform: uppercase;
                    }

                    ul {
                        padding: 0;

                        li {
                            list-style: none;
                            text-transform: uppercase;
                        }
                    }

                    .options.checkbox {
                        ul {
                            li {
                                display: table;

                                &:hover {
                                    .cbx-container .cbx {
                                        border-color: black;
                                    }
                                }

                                .cbx-container {
                                    vertical-align: middle;
                                    display: table-cell;

                                    &+label {
                                        margin-left: 10px;
                                        font-size: 0.9em;
                                    }
                                }
                            }
                        }
                    }

                    .options.button-box {
                        ul {
                            li {
                                display: inline-block;
                                float: left;
                                margin-right: -1px;
                                margin-top: -1px;

                                a {
                                    display: inline-block;
                                    color: black;
                                    border: 1px solid #efefef; // border: 1px solid rgba(0,0,0,.15);
                                    padding: 3px 5px;
                                    font-size: 0.9em;

                                    &:hover {
                                        text-decoration: none;
                                        background: black;
                                        color: white;
                                        border-color: black;
                                    }
                                }
                            }
                        }
                    }

                    &.size {
                        li {
                            a {
                                width: 40px;
                                height: 40px;
                                line-height: 32px;
                                text-align: center;
                            }
                        }
                    }

                    &.color {
                        li {
                            display: inline-block;

                            a {
                                margin: 8px 5px 0 5px;
                                width: 25px;
                                height: 25px;
                                display: inline-block;
                                text-align: center;

                                &.black {
                                    background: black;
                                }

                                &.red {
                                    background: red;
                                }

                                &.blue {
                                    background: blue;
                                }

                                &.white {
                                    background: white;
                                    border: 1px solid #CCC;
                                }
                            }

                            span.color-span {
                                display: inline-block;
                                height: 10px;
                                width: 10px;
                                margin-left: 5px;
                                border: 1px solid transparent;
                            }
                        }
                    }

                    .cbx-container {
                        .cbx {
                            vertical-align: sub;
                            border-radius: 0;
                            box-shadow: none;

                            &:focus,
                            &:hover {
                                box-shadow: none;
                                border-color: black;
                            }
                        }
                    }
                }
            }

            h4 {
                position: relative;

                #clear-all-filters {
                    color: #999;
                    font-size: 16px;
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
        }
    }

    .sort-container {

        // font-family: 'Dosis', sans-serif;
        .sort {
            // margin: 0 0 0 auto;
            float: right; // &:after {

            //     position: absolute;
            //     top: 16px;
            //     right: 25px;
            //     border: 5px solid transparent;
            //     border-top-color: #1a1a1a;
            //     line-height: 30px;
            //     cursor: pointer;
            //     content: " ";
            // }
            .nice-select {
                // border-radius: 0px;
                border: none;
                text-align: center !important;
                width: 150px;
                height: 32px;
                line-height: 16px;

                ul.list {
                    border-radius: 0px;
                    margin-top: 1px;
                    z-index: 990;

                    li {
                        text-align: center;
                        width: 148px;
                    }

                    .option {
                        min-height: 30px;
                        line-height: 30px;
                    }
                }
            }

            select {
                font-family: 'Source Sans Pro', sans-serif; // height: 35px;
                // border: 1px solid #ccc;
                // border-radius: 0;
                // padding: 0 30px 0 10px;
                // background-color: #fff;
                // font-size: .875em;
                // line-height: 35px;
                // letter-spacing: .025em;
                // -webkit-appearance: none;
                // -moz-appearance: none;
                // appearance: none;
                // cursor: pointer;
                // height: 1.8rem;
                // font-size: 0.9em;
                // padding: 0;
                // border: none;
                //     float: right;
                //     width: auto;
            }
        }
    }

    // .no-result {
    //     background: lighten($main-yellow, 10%);
    //     border-color: darken($main-yellow, 5%);
    //     width: 100%;
    //     font-size: 0.85em;
    // }
    .selected-filter-container {
        ul {
            padding: 0;
            margin: 0;

            li {
                list-style: none;
                display: inline-block;
                margin: 0 10px 10px 0;

                a {
                    display: block;
                    position: relative;
                    background: #f2f2f2;
                    height: 35px;
                    line-height: 37px;
                    padding: 0 28px 0 12px;
                    color: black;
                    font-size: .875em;
                    letter-spacing: .025em;

                    &:hover {
                        text-decoration: none;
                        background: darken(#f2f2f2, 10%);
                    }

                    &:after {
                        font-size: 1em;
                        position: absolute;
                        height: 35px;
                        line-height: 35px;
                        right: 12px;
                        top: 0px;
                        z-index: 2;
                        content: 'x';
                        pointer-events: none;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.pagination {
    width: 100%; // font-family: 'Dosis', sans-serif;

    li {

        a,
        span {
            display: inline-table;
            color: black;
            width: 25px;
            padding: 15px;
        }

        &.active {
            span {
                text-decoration: underline;
            }
        }
    }

    // ul {
    //     padding: 0;
    //     // margin: 0 auto;
    //     margin: 0 0 0 auto;
    //
    //
    //     li {
    //         list-style: none;
    //         display: inline-block;
    //         // margin-right: 15px;
    //
    //         // &:last-child {
    //         //     margin-right: 0;
    //         // }
    //
    //         a {
    //             display: inline-table;
    //             color: black;
    //             width: 25px;
    //             padding: 15px;
    //         }
    //     }
    // }
}

.back-to-top {
    position: fixed;
    right: 0;
    bottom: 0;
    margin: 30px;
    display: block;
    opacity: 0;

    img {
        width: 50px;
    }

    &.show {
        opacity: 1;
    }
}

#product-detail-wrapper {

    [class*="thumbnail-container"],
    .other-color-container {
        .item {
            &:hover {
                border-color: rgba(0, 0, 0, .8);
            }
        }
    }

    .thumbnail-container {
        .item {
            width: 60px !important;
            height: 60px;
            border: 1px solid rgba(0, 0, 0, .15);
            @include vc;
            margin-bottom: 7px;
            cursor: pointer;
            outline: none !important;

            img {}
        }
    }

    .thumbnail-container2 {
        height: 60px;
        margin-bottom: 1.5em;

        .item {
            width: 60px !important;
            height: 60px;
            border: 1px solid rgba(0, 0, 0, .15);
            @include vc;
            float: left;
            margin-right: 7px;
            margin-bottom: 7px;

            img {
                max-height: 100%;
            }

            &:last-child {
                margin-right: 0;
            }

            &:focus {
                outline: 0;
            }
        }
    }

    .main-photo-container {
        @include vc;
    }

    .detail-container {
        h1 {
            margin: 0;
            letter-spacing: 2px;
            text-transform: uppercase;
            font-size: 1.75rem;
        }

        .code-color {
            font-size: 0.95em;
            color: #888;
            margin-bottom: 0.5em;
            text-transform: uppercase;
        }

        .price {
            font-size: 1.2em;
            letter-spacing: 1px;

            .tips {
                color: black;
            }
        }
    }

    .cart-container {
        margin-top: 3.5em;

        .col {
            padding: 0;

            &.error {
                .nice-select {
                    border-color: $main-red;
                }
            }
        }

        select {
            border-radius: 0;
            -webkit-appearance: none;
            text-align-last: center;
            text-align: center;
            font-family: 'Source Sans Pro', sans-serif;
        }

        .nice-select {
            border-radius: 0px;
            border-color: #d9d9d9;
            text-align: center !important;

            .current {
                text-transform: uppercase;
            }

            ul.list {
                border-radius: 0px;
                margin-top: 1px;
                max-height: 220px;
                overflow: auto;

                li {
                    text-align: center;
                }
            }
        }

        button {
            width: 100%;
            height: 50px;
            color: white;
            background: #131313;
            border: 1px solid #131313;
            letter-spacing: .1em;
            text-transform: uppercase;
            font-size: .75em;
            cursor: pointer;
            @include transition-2(0.1s);
            font-family: 'Source Sans Pro', sans-serif;

            &:hover {
                background: #ccc;
                border-color: #ccc;
                color: black;
            }
        }

        .size-ship-container {
            a {
                text-transform: uppercase;

                img {
                    width: 22px;
                    height: 22px;
                    margin-right: 5px;
                }

                span {
                    vertical-align: middle;
                }

                color: #2c2e2f;
                margin-right: 2em;

                &:hover {
                    text-decoration: none;
                    opacity: 0.8; // color: @main-blue;
                    // color: lighten(#2c2e2f, 20%);
                }
            }
        }
    }

    .other-color-container {
        margin-bottom: 3em;

        .item {
            // border: 1px solid #eaeaea;
            border: 1px solid rgba(0, 0, 0, .15);
            width: 50px;
            height: 50px;
            @include vc;
            margin-right: 4px;
            cursor: pointer;

            img {
                width: 60px;
            }
        }

        .active {
            border-color: rgba(0, 0, 0, .8);
        }
    }

    .more-information-container {
        padding: 50px 0;
        background: #f6f6f6;
        color: #2c2e2f;

        h3 {
            margin-bottom: 1em;
            text-transform: uppercase;
        }
    }

    .suggest-products-container {
        margin: 5em 0;
    }

    #sizeChartModal {
        .modal-content {
            border-radius: 0;
        }

        .modal-body {
            table {
                margin: 0 auto;
            }
        }

        .modal-wrapper {
            width: 100%;
            overflow-x: auto;
        }
    }

    .product-loading-container {
        min-height: 250px;
        display: table;

        .loading-wrapper {
            text-align: center;
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.breadcrumb-container.normal {
    margin-bottom: 1em;

    .breadcrumb {
        margin: 0;
        padding: 0;
        background: transparent;

        .breadcrumb-item {
            color: $main-black;
            text-transform: uppercase;

            &.active {
                color: #888;
            }
        }

        .breadcrumb-item+.breadcrumb-item::before {
            content: ">";
        }
    }
}

$pushy-width: 250px;

.pushy {
    width: $pushy-width;
    background: white;
    text-transform: uppercase;

    ul {
        padding: 0;

        li {
            list-style: none;
        }
    }

    a {
        color: #2c2e2f;

        &:hover {
            color: #2c2e2f;
            text-decoration: underline;
        }
    }

    * {
        @include transition-2(0.1s);
    }

    .pushy-submenu {
        button {
            color: #2c2e2f;
            font-family: inherit;
            cursor: pointer;
            text-transform: uppercase;

            &:hover {
                text-decoration: underline;
            }

            &:focus {
                outline: none;
            }
        }

        a {
            padding: 5px 30px 5px 45px;
        }

        &>a::after,
        &>button::after {
            background: url('/images/down-right.png') no-repeat;
            width: 12px;
            height: 12px;
            background-size: 12px 12px;
        }
    }
}

.pushy-left {
    -webkit-transform: translate3d(-$pushy-width, 0, 0);
    -ms-transform: translate3d(-$pushy-width, 0, 0);
    transform: translate3d(-$pushy-width, 0, 0);
}

.pushy-open-left {
    main#container {
        -webkit-transform: translate3d($pushy-width, 0, 0);
        -ms-transform: translate3d($pushy-width, 0, 0);
        transform: translate3d($pushy-width, 0, 0);
    }

    .site-overlay {
        background-color: rgba(0, 0, 0, .2);
    }

    .pushy {
        box-shadow: 1px 0px 5px #888888;
    }
}

.vex.vex-theme-wireframe .vex-content {
    font-family: 'Source Sans Pro', sans-serif;
}

#cart-wrapper {
    min-height: 550px;
    padding-top: 3em;
    margin-bottom: 3em; // .no-result {

    //     background: lighten($main-yellow, 10%);
    //     border-color: darken($main-yellow, 5%);
    //     width: 100%;
    //     font-size: 0.85em;
    // }
    .cart-table {
        h4 {
            margin-bottom: 1em;
            border-bottom: 1px solid #212121;
            padding-bottom: 0.5em;
            text-transform: uppercase;
            letter-spacing: 0.03em;

            small {
                font-size: 70%;
                margin-left: 10px;
                text-transform: lowercase;
            }
        }

        .cart-table-head {
            padding: 10px; // background: black;
            // color: white;
            border-top: 4px solid black;
            border-bottom: 1px solid black;
        }

        .cart-table-body {
            .item {
                padding: 15px 10px;

                &:not(:last-child) {
                    border-bottom: 1px solid #e5e5e5;
                }

                // &:last-child {
                //     border-bottom: 1px solid black;
                // }
                a.image {
                    display: block;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-position: center;
                    float: left;
                    width: 75px;
                    height: 75px;
                }

                .description {
                    margin-left: 85px;
                    word-wrap: break-word;
                    margin-bottom: 5px;

                    span {
                        display: block;
                    }

                    span.name {
                        // font-weight: bold;
                        text-transform: uppercase;

                        a {
                            color: black;
                        }
                    }

                    span.details {
                        font-size: 0.85em;
                        text-transform: uppercase;
                    }

                    span.actions {
                        a {
                            color: #888;
                            font-size: 0.85em;
                        }

                        // .edit {
                        //     margin-right: 10px;
                        // }
                        // .remove {
                        //     margin-left: 10px;
                        // }
                    }
                }

                .price {
                    line-height: 38px;
                    text-align: center;
                }

                .qty {
                    padding: 0 5px;

                    input {
                        border-radius: 0;
                        border-color: rgba(0, 0, 0, .15);
                        text-align: center;
                        font-family: 'Source Sans Pro', sans-serif;

                        &:focus,
                        &:active {
                            border-color: black;
                        }
                    }
                }

                .subtotal {
                    line-height: 38px;
                    text-align: center;
                }
            }
        }
    }

    .process-checkout-container {
        text-align: center;

        a {
            display: inline-block;
            height: 50px;
            line-height: 50px;
            @extend .general-btn;
        }
    }
}

#checkout-wrapper {
    .section {
        h4 {
            margin-bottom: 1em;
            border-bottom: 1px solid #212121;
            padding-bottom: 0.5em;
            text-transform: uppercase;
        }

        label {
            text-transform: uppercase;
            margin-bottom: 0;
            font-size: 0.85em;

            * {
                @extend .no-transition;
            }
        }

        input,
        textarea {
            border-radius: 0;

            &:focus {
                border-color: black;
            }
        }

        .nice-select {
            border-radius: 0;
            border: 1px solid rgba(0, 0, 0, .15);

            ul.list {
                margin-top: 0px;
                border-radius: 0px;
            }
        }
    }

    .user-container {}

    .delivery-container {
        margin-top: 4rem !important; // border-bottom: 1px solid black;

        .methods {
            // padding: 10px;
            margin-bottom: 1rem;

            .method {
                margin-right: 20px;
                display: inline-block;

                label>div {
                    margin-right: 5px;
                }
            }
        }

        [name="ddlSFStore"] {
            border-radius: 0;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-position: right 50%;
            background-repeat: no-repeat;
            background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
            padding: .5em;
            padding-right: 1.5em;
            font-size: 0.85em;

            &:focus {
                border-color: black;
            }
        }

        .sfstore-map-infowindow {
            h6 {
                // margin-bottom: 1em;
                text-transform: uppercase;
            }

            p {
                margin-bottom: 0.75em;
            }

            button {
                text-transform: uppercase;
                background: black;
                color: white;
                border: 0;
                padding: 7px 15px;
            }
        }
    }

    .remarks-container {
        margin-top: 4rem !important; // border-bottom: 1px solid black;

        .form-group {
            margin-top: 1rem;
        }
    }

    .billing-checkbox-label {
        span {
            vertical-align: middle;
            margin-left: 5px;
            text-transform: lowercase;
        }
    }

    .billing-container {
        margin-top: 4rem !important;
    }

    #btnSubmit {
        @extend .general-btn;
        display: inline-block;
        height: 50px;
        padding: 0 1vw;
    }

    .parsley-errors-list {
        padding: 0;
        list-style: none;
        font-size: 0.8em;
        padding-top: 3px;

        li {
            color: #bb3d3d;
        }
    }

    .form-group {
        .helper {
            font-size: 0.8em;
            color: $main-black;
        }
    }
}

#payment-wrapper {
    min-height: 500px;

    .section {
        h4 {
            margin-bottom: 1em;
            border-bottom: 1px solid #212121;
            padding-bottom: 0.5em;
            text-transform: uppercase;
        }
    }

    .method {
        position: relative;
        padding: 1em 0;

        * {
            @extend .no-transition;
        }

        &:not(:first-child) {
            border-top: 1px solid rgba(0, 0, 0, 0.15);
        }

        .option {
            label {
                display: flex;
                align-items: center;
            }

            .checkbox-area {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 40px;
                height: 40px;
                line-height: 40px; // display: inline-block;
                text-align: center;
            }

            h5 {
                display: inline-flex;
                margin: 0;
                text-transform: uppercase;
                margin-left: 10px;
            }
        }

        .content {
            padding-left: 60px;

            ul {
                padding-left: 5px;

                li {
                    font-size: 0.8em;
                    color: $main-black;
                }
            }

            h5 {
                height: 40px;
                line-height: 40px;
                margin: 0;
                text-transform: uppercase;
            }
        }

        &.active.credit-card {
            >.content {
                display: none;
            }

            .credit-card-info-container {
                display: block;
            }
        }

        .credit-card-info-container {
            padding-left: 60px;
            display: none;

            .card-content {
                // background: #efefef;
                // background: -webkit-linear-gradient(left top, #efefef, #eee);
                // background: -o-linear-gradient(bottom right, #efefef, #eee);
                // background: -moz-linear-gradient(bottom right, #efefef, #eee);
                // background: linear-gradient(to bottom right, #efefef, #eee);
                background-color: #424242;
                background-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.1) 50%, rgba(255, 255, 255, 0.1) 5.00000001%, rgba(255, 255, 255, 0) 100%);
                border-radius: 8px;
                margin: 0 auto;
                padding: 3em 1em;
                color: white;

                .row {
                    .form-group {
                        margin-bottom: 0;

                        label {
                            margin-bottom: 2px;
                            font-size: 0.8em;
                            text-transform: uppercase;
                        }
                    }
                }

                [id^='error-card'] {
                    color: $main-red;
                    font-size: 0.8em;
                    margin-top: 2px;
                }

                .StripeElement {
                    background-color: white;
                    padding: 5px;
                    color: white;
                    font-size: 0.8em;
                    border: 1px solid transparent;
                }

                .StripeElement--focus {
                    box-shadow: 0 1px 3px 0 #cfd7df;
                }

                .StripeElement--invalid {
                    border-color: #fa755a;
                }

                .StripeElement--webkit-autofill {
                    background-color: #fefde5 !important;
                }

                [name="cardholder_name"] {
                    width: 100%;
                    background-color: white;
                    padding: 5px;
                    color: white;
                    font-size: 0.8em;
                    border: 1px solid transparent;
                    color: #32325d;
                    line-height: 18px;
                    font-family: "Share Tech Mono", sans-serif;
                    font-size: 14px;
                    -webkit-font-smoothing: antialiased;
                    outline: none;
                }
            }
        }
    }

    #btnSubmit {
        @extend .general-btn;
        display: inline-block;
        height: 50px;
    }
}

#payment-wrapper,
#checkout-wrapper,
#cart-wrapper {

    .checkout-right,
    .payment-right,
    .cart-right {
        padding: 0;
    }
}

.cart-summary,
.checkout-summary {
    background: #ededed;
    padding: 2em 1.5em;

    &.fixed {
        position: fixed;
        width: 100%;
        bottom: 0px;
        left: 0px;
        height: 100%;
        z-index: 999;
    }

    h5 {
        padding-bottom: 1em;
        margin-bottom: 0;
        letter-spacing: .05em; // font-weight: bold;
        text-transform: uppercase;
    }

    .content {

        // .section {
        //     padding: 1em 0;
        //     border-bottom: 1px solid black;
        // }
        .coupon-container {
            border-top: 1px solid black;

            .input-group {
                input {
                    border-radius: 0px;
                    background: transparent;
                    font-family: 'Source Sans Pro', sans-serif;
                    border-color: black;
                    font-size: 0.85em;
                }

                button {
                    border-radius: 0px;
                    border-color: black;
                    color: white;
                    background: #131313;
                    border: 1px solid #131313;
                    font-size: 12px;
                    cursor: pointer;
                    @include transition-2(0.1s);
                    font-family: 'Source Sans Pro', sans-serif;
                    text-transform: uppercase;

                    &:hover {
                        background: #ccc; // border-color: #ccc;
                        color: black;
                    }
                }
            }

            .applied-coupon-container {
                .coupon {
                    button {
                        position: relative;
                        height: 34px;
                        line-height: 34px;
                        font-size: 0.75em;
                        padding: 0 34px 0 18px;
                        background: transparent;
                        border: 1px solid black; // font-weight: 300;
                        cursor: pointer;

                        &:after {
                            content: 'x';
                            position: absolute;
                            right: 0;
                            width: 34px;
                            height: 34px;
                            top: -1px;
                        }

                        &:hover {
                            background: black;
                            color: white;
                        }
                    }
                }
            }
        }

        .destination-container {
            border-top: 1px solid black;

            .nice-select {
                border-radius: 0;
                background: transparent;
                border-color: black;
                height: 34px;
                line-height: 34px;

                .list {
                    margin-top: 0px;
                    border-radius: 0px;
                }
            }
        }

        .total-container {
            table {
                margin: 0;
                line-height: 1.7rem;
                letter-spacing: .03em;

                tr {
                    font-size: 0.85em;

                    td:nth-child(2) {
                        text-align: right;
                    }

                    &.large {
                        font-size: 1.2em; // font-weight: bold;
                        text-transform: uppercase;
                    }

                    &.subtotal {
                        td {
                            padding-top: 5px;
                            padding-bottom: 5px;
                            border-top: 1px solid black;
                        }
                    }

                    &.total {
                        td {
                            padding-top: 10px;
                            padding-bottom: 10px;
                            border-top: 1px solid black;
                            border-bottom: 1px solid black;
                        }
                    }
                }
            }
        }

        .checkout-information-container {
            padding: 10px 3px 0;
            position: relative;

            p {
                font-size: 0.85em;
            }

            a {
                @extend .general-btn;
                padding: 3px 5px;
                position: absolute;
                min-width: 38px;
                text-align: center;
                top: 10px;
                right: 5px;
            }
        }

        .checkout-products-container {
            padding: 3em 5px 0px;

            h5 {
                text-transform: uppercase;
                padding: 0;
                color: $main-black;
                font-size: 1.2em;
            }

            .product-list {
                list-style: none;
                padding: 0;
                margin: 0;

                li.item {
                    padding: 10px 5px;
                    border-bottom: 1px solid #888;

                    &:last-child {
                        border-bottom: none;
                    }

                    img {
                        float: left;
                        width: 85px;
                    }

                    .content {
                        padding-left: 95px;

                        p {
                            margin-bottom: 3px;
                            text-transform: uppercase;
                            line-height: 1.1em;
                            font-size: 0.9em;
                        }

                        span {
                            font-size: .7em;
                            color: $main-black;
                            display: block;
                            line-height: 1.2em;
                            text-transform: uppercase;

                            &.price {
                                div {
                                    display: inline-block;

                                    s {
                                        color: #999;
                                    }
                                }

                                .ml-l {
                                    margin-left: -2px;
                                    margin-right: 7px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.alert {
    &.no-result {
        background: lighten($main-yellow, 10%);
        border-color: darken($main-yellow, 5%);
    }

    &.success {
        background: lighten($brand-success, 10%);
        border-color: darken($brand-success, 5%);
    }

    width: 100%;
    font-size: 0.85em;
    border-radius: 0;

    &.alert-dismissible {
        button {
            height: 44px;

            span {
                font-size: 0.7em;
                vertical-align: middle;
                font-weight: 300;
                display: block;
            }
        }
    }
}

#checkout-completed-wrapper {
    h4.title {
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, .85);
        text-transform: uppercase;
        padding: 0.5em;
        margin-bottom: 10px;
        width: 100%;
    }

    .thanks-notice {
        padding: 1em;

        p {
            margin-bottom: 0px;
        }
    }

    .bank-transfer-notice {
        padding: 1em;

        p {
            margin-bottom: 0px;
        }
    }

    .ordered-items {
        width: 100%;
        padding: 1em;

        .item {
            img {
                width: 100px;
                float: left;
                margin-right: 10px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid rgba(0, 0, 0, .15);
            }

            .description {
                span {
                    display: block;
                }
            }
        }
    }

    .order-information {
        width: 100%;
        padding: 1em;

        .customer-table {
            border-bottom: 0px !important;
        }

        .customer-table th,
        .table td {
            border: 1px !important;
        }
    }
}

#checkout-error-wrapper {
    min-height: 500px;

    h4.title {
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, .85);
        text-transform: uppercase;
        padding: 0.5em;
        margin-bottom: 10px;
        width: 100%;
    }

    .error-message {
        padding: 1em;

        p {
            margin-bottom: 0px;
        }
    }
}

#brands-list-wrapper {
    .brands-group {
        a {
            display: inline-block;
            color: lighten($main-black, 10%);
            text-transform: uppercase; // @media(max-width: 576px) {
            margin-bottom: 10px; // }
        }
    }
}

#pages-wrapper {
    min-height: 500px;
}

#contact-wrapper {
    #map {
        width: 100%;
        height: 450px;
    }
}

.web-pages {
    .container {
        max-width: 700px;
        text-align: center;
    }
}

#search-wrapper {
    min-height: 500px;
    padding-bottom: 3em;
}

//zopim
.meshim_widget_components_ChatButton.br .button_bar {
    border-radius: 0 !important;
}

@media (max-width: 560px) {
    ul.pagination li:not(.show-mobile):not(.disabled) {
        display: none;
    }
}

#login-wrapper {
    ul.action-options {
        padding: 0;
        list-style: none;
        white-space: nowrap;
        display: block;

        li {
            display: inline;
            text-align: center;
            text-transform: uppercase;

            &.login-tab.selected~hr {
                margin: 0;
            }

            &.login-tab:hover~hr {
                margin: 0 !important;
            }

            &.register-tab:hover~hr,
            &.register-tab.selected~hr {
                margin-left: 50%;
            }
        }

        a {
            display: inline-block;
            width: 50%;
            padding: .75rem 0;
            margin: 0;
            text-decoration: none;
            color: #333;
        }

        hr {
            height: 1px;
            width: 50%;
            margin: 0;
            border: none;
            background: black;
            transition: .3s ease-in-out;

            &.on-register {
                margin-left: 50%;
            }
        }
    }

    .tab-content {
        padding-top: 2rem;
        padding-bottom: 2rem;

        .form-group {
            text-transform: uppercase;
            font-size: 0.85em;

            label {
                margin-bottom: 0;
            }

            input {
                border-radius: 0;

                &:focus {
                    border-color: black;
                }
            }
        }


        .login-tab-content {
            .facebook-login {
                background-color: #3B5998;
                color: white;
                width: 100%;
                padding: 7px;
                border: 0;
                cursor: pointer;

                &:hover {
                    background-color: darken(#3B5998, 5%);
                }

                i {
                    margin-right: 5px;
                }
            }

            .or-separator {
                display: block;
                position: relative;
                text-align: center;
                margin-top: 2rem;
                margin-bottom: 2rem;

                span {
                    padding: 0 20px;
                    background-color: white;
                    display: inline-block;
                    color: #888;
                }

                &:after {
                    position: absolute;
                    content: ' ';
                    height: 1px;
                    width: 100%;
                    background-color: #ccc;
                    top: 50%;
                    left: 0;
                    z-index: -1;
                }
            }

            // input[name="remember"] {
            //     font-size: initial;
            // }
        }


        button[type="submit"] {
            @extend .general-btn;
            height: 45px;
            font-size: 0.75rem;

            &~a {
                font-size: 0.8em;
                color: black;
            }
        }

        .parsley-errors-list {
            padding: 0;
            list-style: none;
            font-size: .9em;
            margin-top: .25rem;
            margin-bottom: 0;
        }
    }
}

#profile-wrapper {
    button[type="submit"] {
        @extend .general-btn;
        height: 45px;
        font-size: 0.75rem;

        &~a {
            font-size: 0.8em;
            color: black;
        }
    }

    .form-group {
        text-transform: uppercase;
        font-size: 0.85em;

        label {
            margin-bottom: 0;
        }

        input {
            border-radius: 0;

            &:focus {
                border-color: black;
            }
        }
    }

    .parsley-errors-list {
        padding: 0;
        list-style: none;
        font-size: .9em;
        margin-top: .25rem;
        margin-bottom: 0;
    }
}